@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins/index.scss";

.infoGImmoGestProp {
  padding: 10px 10px;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  @include hiddenScrollbar;
  .ant-row{
    .ant-form-item {
      padding-right: 20px;
      margin-bottom: 0px;
      .ant-form-item-label label{
          width: fit-content !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-top: 5px;
          display: block;
          text-align: left;
          font-size: 12px;
      }
      textarea{
          overflow: hidden;
      }
    }
    .lg-label .ant-form-item-label label{
      width: 180px !important;
    }
    .divided_col {
        display: flex;
        width: 100%;
        padding-left: 90px;
        justify-content: flex-start;

    }
    .radioo{
      .ant-form-item-label label{
        width: 120px !important;
      }
    }
}
.radius-slider {
  display: flex;
  flex-direction: row;
  width: 100%;
  & > p {
    margin-right: 7px;
  }
  & > div {
    width: 80%;
    margin-top: 7px;
  }
}
#map-disclaimer {
  margin-bottom: 5px;
  text-align: center;
  font-weight: bold;
}
.leaflet-parent {
  width: 100%;
  height: 300px;
  margin-bottom: 45px;
}
.limite:has(> .leaflet-parent)   {
  padding: 10px;
}
.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 6px;
  .leaflet-marker-icon {
    color: blue($color: #000000);
    
    &:last-child {
      filter: hue-rotate(260deg);
    }
  }
}
.limite{
  border-bottom: 1px solid $blue-green;
  border-right: 1px solid $blue-green;
  border-left: 1px solid $blue-green;
  padding: 10px 0px 10px 10px;
}
.ant-tabs-nav{
  margin: 0px;
}
.ant-tabs-content-holder{
  .limite{
    padding: 20px 5px 10px 5px;
    border-bottom: 1px solid $border_primary;
    border-right: 1px solid $border_primary;
    border-left: 1px solid $border_primary;
  }
}
.ant-divider-horizontal.ant-divider-with-text-left {
  margin: 0;
  &::before {
    height: 20px;
    border-top: 1px solid $blue-green !important;
    border-left: 1px solid $blue-green;
  }
  &::after {
    height: 20px;
    border-top: 1px solid $blue-green!important;
    border-right: 1px solid $blue-green;
  }
  .ant-divider-inner-text {
    font-size: 14px;
    font-weight: 700;
  }
}
  .submodule{
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .divided_col, .pushed_col{
    padding-left: 0px !important;
  }
}
