@import "../variables";

@mixin generic_page_style {
  height: 100%;
  padding: 10px 20px;
  .page_container{
    width: 100%;
    background-color:$white;
  }
}
@mixin gridToolbarStyle {
  .k-toolbar{
    overflow-x: auto;
  .toolbar_utils {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    .toolbar_btns {
      .ant-btn {
        margin-right: 10px;
      }
    }
  }
}
}
@mixin hiddenScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin dividerStyle{
  .ant-divider-horizontal.ant-divider-with-text-left {
    margin: 0;
    &::before {
      border-top: 1px solid $blue-green !important;
      border-left: 1px solid $blue-green;
    }
    &::after {
      border-top: 1px solid $blue-green!important;
      border-right: 1px solid $blue-green;
    }
    &.ant-divider-inner-text {
      font-size: 14px;
    }
  }
}
@mixin vertical_Menu {
  .ant-menu-vertical {
    background-color: $white !important;
    .ant-menu-submenu-selected {
      color: $active_item;
    }
  }
}
@mixin generic_grid {
  .ant-spin-nested-loading {
    flex: 1 1 auto;
    height: auto;
  }
  .k-grid-header {
    padding: 0 !important;
    th.k-sorted {
      color: $dark_blue;
      font-weight: 700;
    }
  
    tr > th {
      font-size: $grid_font_size;
      font-weight: 500;
    }
  }
  .k-grid-content {
    overflow-y: overlay !important;
    tr {
      &:hover {
        cursor: pointer;
      }
      td {
        font-size: $grid_font_size;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .k-grouping-row{
      td{
        background-color: #3693e0 !important;
      }
    }
    .k-group-cell{
      background-color: #3693e0 !important;
    }
    .k-grid-edit-row{
      .k-grid-edit-cell{
        padding: 3px;
        input{
          font-size: $grid_font_size;
        }
      }
    }
  }
  
  // smaller grids
  .k-grid-sm .k-grid-header {
    tr > th {
      font-size: $grid_font_size_sm;
    }
  }
  .k-grid-sm .k-grid-content {
    tr {
      td {
        font-size: $grid_font_size_sm;
      }
    }
    .k-grid-edit-row{
      .k-grid-edit-cell{
        input{
          font-size: $grid_font_size_sm;
        }
      }
    }
  }

  //weird 0 width pb
  .k-grid-header-wrap table {
    width: 100% !important;
  }
  .k-grid-table {
    width: 100% !important;
  }
}
@mixin generic_drawer{
  width: 100%;
  display: flex;
  flex-flow: column ;
  justify-content: start;
  height: calc(100vh - 145px );
  .ant-menu-overflow {
    width: 100%;
    border-bottom: 0px;
    .suivi{
      margin-left: auto;
    }
  }
  .ant-form{
    height: 100%;
    .ant-form-item-control{
      margin-bottom: 0.5rem;
    }
    .allContainer{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      height: 100%;
      .ant-col{
        height: 100%;
      }
      .ficheContent{
        height: 100%;
        border: 1px solid $border_primary;
        padding-bottom: 10px;
        .maincontent{
          @include hiddenScrollbar;
          padding: 10px 10px;
          overflow: hidden;
          overflow-y: scroll;
          height: calc(100% - 57px);
        }
      }
      .suiviHeader{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
      .suiviContent{
        height: 100%;
        padding: 10px;
        border: 1px solid $border_primary;
        border-radius: 0.25rem;
        .scrollIt{
          overflow: hidden;
          overflow-y: scroll;
          height: calc(100% - 130px);
          @include hiddenScrollbar;
        }
        .ant-comment-content-author{
          &:after{
            position: relative;
            top: 50%;
            width: 100%;
            border-bottom: 0;
            transform: translateY(50%);
            content: '';
          }
          .ant-comment-content-author-name{
            width: 100%;
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .suiviContent{
          margin-top: 20px;
          width: 100%;
        }
     }
    }
  }
}

