@import "../../assets/styles/variables";

.breadcump_container {
  font-size: 1.3rem;
  font-weight: 500;
  color: $dark_blue;
  width: 100%;
  margin: 0 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  height: 10%;
  margin: 15px 0px 20px 2px;
  /*
  &::before {
    content: "";
    width: 1px;
    height: 40px;
    background-color: $black_title;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 15px 0 0;
  }

   &::after {
     content: "";
     width: 100%;
     height: 1px;
     background-color: $black_title;
     display: -webkit-box;
     display: -webkit-flex;
     display: -ms-flexbox;
     display: flex;
     margin: 0 0 0 15px;
   }
   */
}
