.addTiersForm {
    .ant-row{
      justify-content: space-between;
      .ant-form-item {
        padding-right: 20px;
        margin-bottom: 0px;
        .ant-form-item-label label{
            width: 90px !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-top: 5px;
            display: block;
            text-align: left;
            font-size: 12px;
        }
        textarea{
            overflow: hidden;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
            background-color: black !important;
        }
      }
      .divided_col, .pushed_col {
          display: flex;
          width: 100%;
          padding-left: 90px !important;
          justify-content: flex-start;
  
      }
      .ant-radio-button-wrapper{
        &.active{
          box-shadow: none;
        }
      }
      .radioo{
        .ant-form-item-label label{
          width: 120px !important;
        }
      }
  }
}