.table_container_contact_tiers {
  height: 100%;
    .tool_buttons {
      width: 100%;
      justify-content: flex-end;
      button:nth-child(2){
          margin-left: 10px;
      }
    }
    .k-grid{
        height: fit-content !important;
        max-height: 100% !important;
    }
  }
  