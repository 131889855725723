@import '../../assets/styles/variables';

.userMenu{
    position: relative;
    img{
        width: 40px;
        border-radius: 20px;
        cursor: pointer;
    }
    .online{
        width: 10px;
        height: 10px;
        border-radius: 20px;
        background-color: $green_online;
        position: absolute;
        display: block;
        border: 1px solid $white;
        bottom: 14px;
        right: 1px;
    }
}
/* User Menu Popover*/
.ant-popover-placement-bottomRight{
    top: 52px !important;
}
/* User menu content*/
.ant-popover-inner-content{
    padding: 7px 0;
    color: $dark_grey;
    .isdropdowUserMenu:first-child{
        font-weight: 900;
    }
    .isdropdowUserMenu{
        padding: 7px 15px;
        margin: 0;
        font-size: 13px;
        line-height: 1.1;
        cursor: pointer;
        &:hover{
            background-color: $grey_bckg;
        }
    }
}