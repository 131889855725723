@import "../../assets/styles/variables";
@import "../../assets/styles//mixins/index.scss";

.addImmoConfig_modal {
  .ant-form {
    .ant-row {
      margin-bottom: 0;
      &.checkbox {
        label {
          width: 200px !important;
        }
      }
      input {
        font-size: 12px;
      }
      label {
        margin-bottom: 0;
        width: 110px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-top: 5px;
        display: block;
        text-align: left;
        font-size: 12px;
      }
    }
  }
}
