@import "../../assets/styles/variables";

.generic_select{
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        .ant-select-selector{
            border-color: $border_primary !important;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        }   
    }

}
