@import "../../assets/styles/variables";
@import "../../assets/styles/mixins/index.scss";

.immoGestPropFiche {
  @include generic_drawer;
  .submodule{
    .ant-tabs-nav{
      margin: 0px;
    }
  }

}
