@import "../../../assets/styles/variables";
@import "../../../assets/styles//mixins/index.scss";

.immoPicFormContainer {
  .dropzoneContainer{
    height: 400px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
    .dropzone{
      margin: auto;
      i{margin-right: 10px;}
    }
  }
  .simpleImg{
    height: 400px;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .picForm{
    text-align: right;
    .formItem{
      display: flex;
      flex-flow: column nowrap;
      width: 70%;
      margin-left: auto;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      margin-bottom: 20px;
      label{
        text-align: left;
        padding-bottom: 5px;
        font-weight: 400;
      }
    }
    .ant-badge{
      width: 70%;
      .rejection{
        padding: 40px 20px 20px 20px;
        display: flex;
        flex-flow: column nowrap;
        border-width: 2px;
        border-radius: 2px;
        border-color: #ff0000;
        border-style: dashed;
        background-color: rgb(254, 126, 126);
        color: white;
        ul{
          font-weight: 400;
          text-align: left;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .formItem {
      margin-top: 10px;
      padding: 30px 10px 0px 10px;
      flex-flow: row nowrap !important;
      width: 100% !important;
      justify-content: flex-start;
      label{
        margin-right: 20px;
      }
    }
    .ant-badge{
      width: 100% !important;
      .rejection{
        padding: 40px 20px 20px 20px;
        display: flex;
        flex-flow: column nowrap;
        border-width: 2px;
        border-radius: 2px;
        border-color: #ff0000;
        border-style: dashed;
        background-color: rgb(254, 126, 126);
        color: white;
        ul{
          font-weight: 400;
          text-align: left;
        }
      }
    }
  }
}
