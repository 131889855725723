@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*:)*/

.k-scheduler-layout::-webkit-scrollbar,.treeContainer::-webkit-scrollbar,.k-scheduler-monthview::-webkit-scrollbar, .k-toolbar::-webkit-scrollbar, .planingComponent::-webkit-scrollbar,.k-grid-content::-webkit-scrollbar, textarea::-webkit-scrollbar, .ant-checkbox-group::-webkit-scrollbar, 
.inputNbrSlider::-webkit-scrollbar, .ant-transfer-list-content::-webkit-scrollbar, .picsContainer::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
  height: 6px;
}

.k-scheduler-layout::-webkit-scrollbar-track,.treeContainer::-webkit-scrollbar-track,.k-scheduler-monthview::-webkit-scrollbar-track, .k-toolbar::-webkit-scrollbar-track, .planingComponent::-webkit-scrollbar-track, .k-grid-content::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track, .ant-checkbox-group::-webkit-scrollbar-track, 
.inputNbrSlider::-webkit-scrollbar-track, .ant-transfer-list-content::-webkit-scrollbar-track, 
.picsContainer::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.k-scheduler-layout::-webkit-scrollbar-thumb,.treeContainer::-webkit-scrollbar-thumb,.k-scheduler-monthview::-webkit-scrollbar-thumb, .k-toolbar::-webkit-scrollbar-thumb, .planingComponent::-webkit-scrollbar-thumb,.k-grid-content::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb, .ant-checkbox-group::-webkit-scrollbar-thumb,    
.inputNbrSlider::-webkit-scrollbar-thumb, .ant-transfer-list-content::-webkit-scrollbar-thumb, 
.picsContainer::-webkit-scrollbar-thumb {
  background-color: gray; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 2px solid transparent; /* creates padding around scroll thumb */
}
.anticon-close-circle svg{
  color: #F72929;
}

