@import "../../assets/styles/variables";
@import "../../assets/styles//mixins/index.scss";

.loginCardComponent{
    width: 420px;
    height: 400px;
    background-color: $white;
    border-radius: 20px !important;
    .ant-card-body{
        padding: 70px 55px;
        text-align: left;
        .title{
            color: $black_title;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .ant-form{
            margin-top: 15px;
            margin-bottom: 50px;
            .ant-form-item{
                margin-bottom: 10px !important;
                .ant-input-affix-wrapper{
                    border-radius: 10px;
                }
                .ant-input-suffix{
                    border-radius: 10px;
                }
            }
        }
        button{
            width: 100%;
            border-radius: 10px;
            text-align: center;
        }
    }
}