
.confirm_modal{
    .ant-modal-body{
        padding: 30px 82px 10px 0px;
        .container_confirm_modal{
            display: flex;
            flex-flow: row nowrap;
            .mainText{
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                p{
                    width: 100%;
                    margin-bottom: 5px;
                }
                .secondPg{
                    font-style: italic;
                    font-size: 11px;
                    font-weight: bold;
                }
            }
            span{
                width: 30%;
                font-size: 25px;
                color: #ff9966;
            }
        }
    }
}

