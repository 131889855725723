@import "../../assets/styles/mixins/index.scss";

.row_rank {
  text-align: right;
  padding-right: 85px;
  padding-bottom: 5px;
  button {
    &:nth-child(2){
      margin-left: 5px;
    }
    padding: 0px 5px;
    i {
      margin: 0;
    }
  }
}
.transferlist {
  justify-content: center;
  .ant-transfer-list-header-dropdown {
    display: none;
  }
  .ant-transfer-list-header-selected {
    display: none;
  }
  .ant-transfer-list-header-title {
    text-align: left;
    padding-left: 15px;
    font-weight: 600;
  }
}
