@import '../../assets/styles/variables';
@import '../../assets/styles/animation/index.scss';

.sidebar_logo{
    height: 70px;
    background: $bkg_sidebar_logo;
    text-align: center;
    overflow: hidden;
    img{
        width: 75%;
        padding: 15px 0px;
        //animation-name: logo_spin;
        animation-duration: 4s;
    }
    h3{
        line-height: 70px;
        color: $white;
        font-size: 21px ;
        font-weight: 300 ;
        letter-spacing: 3px ;
    }
}


