@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins/index.scss";

.infoGContact {
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-row{
    justify-content: space-between;
    .ant-form-item {
      padding-right: 20px;
      margin-bottom: 0px;
      .ant-form-item-label label{
          width: 90px !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-top: 5px;
          display: block;
          text-align: left;
          font-size: 12px;
      }
      textarea{
          overflow: hidden;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
          background-color: black !important;
      }
    }
    .divided_col, .pushed_col {
        display: flex;
        width: 100%;
        padding-left: 90px !important;
        justify-content: flex-start;

    }
    .radioo{
      .ant-form-item-label label{
        width: 120px !important;
      }
    }
}
  .submodule{
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    display: flex;
    margin-top: 20px;
    .ant-tabs {
      flex: 1;
    }
    .ant-tabs-nav{
      margin-bottom: 0;
    }
    .ant-tabs-content-holder{
      flex: 1;
      border: 1px solid $border_primary;
      border-top: none;
      padding: 10px 10px;
    }
    .ant-tabs-content {
      height: 100%;  
    }
    .ant-tabs-tabpane{
      height: 100%;
    }
    .ant-tabs-pane {
      height: 100%;
    }
    .tab_container {
      height: 100%;
      display: flex;
    }
  }
}
@media only screen and (max-width: 575px) {
  .divided_col, .pushed_col{
    padding-left: 0px !important;
  }
}
