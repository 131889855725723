@import "../../assets/styles/variables";
@import "../../assets/styles//mixins/index.scss";

.authComponent{
    height: 100vh;
    background-color: $grey_bckg;
    .navbar_login{
        height: 65px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 50px;
        box-shadow: 0px 5px $white;
        img{
            margin-right: 15px;
        }
        .title{
            font-size: 25px;
            font-weight: bolder;
            color: #182c41;
        }
    }
.mainContainer{
    height: calc(100vh - 100px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    .isoLeft{
        height: 100%;
        width: 50%;
        .content{
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            height: 100%;
            .title{
                display: block;
                color: $black_title;
                width: 60%;
                margin-bottom: 95px;
                text-align: initial;
                min-width: 160px;
                h1{
                    font-size: 38px;
                    font-weight: 700;
                    margin: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                h4{
                    font-size: 25px;
                    font-weight: 600;
                    margin: 0;
                    margin-bottom: 15px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                div{
                    line-height: 1.4;
                }
            }
            img{
                width: 50%;
                height: 30%;
            }
        }
    }
    .isoRight{
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50%;
    }
}
.footer_login{
    height: 35px;
    text-align: end;
    padding-right: 20px;
    box-shadow: 0px -5px $white;
}

@media screen and (max-width: 1200px) {
    .isoRight {
      width: 100%;
    }
    .isoLeft {
        display: none;
      }
  }
}