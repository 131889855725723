@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins/index.scss";

.ficheContact {
  padding: 10px 10px;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  @include hiddenScrollbar;
  .ant-row{
    .ant-form-item {
      padding-right: 20px;
      margin-bottom: 0px;
      .ant-form-item-label label{
          width: 90px !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-top: 5px;
          display: block;
          text-align: left;
          font-size: 12px;
      }
      textarea{
          overflow: hidden;
      }
    }
}
  .limite{
      border-bottom: 1px solid $blue-green;
      border-right: 1px solid $blue-green;
      border-left: 1px solid $blue-green;
  }

  .ant-divider-horizontal.ant-divider-with-text-left {
    margin: 20px 0 0 0;
    height: 0;
    padding: 0 0 10px 0;
    &::before {
      border-top: 1px solid $blue-green !important;
      border-left: 1px solid $blue-green;
      height: 12px;
    }
    &::after {
      border-top: 1px solid $blue-green!important;
      border-right: 1px solid $blue-green;
      height: 12px;
    }
    &.ant-divider-inner-text {
      font-size: 14px;
    }
  }
  .ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.60) !important;
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: black !important;
  }
}
