@import "~antd/dist/antd.css";
@import "../../assets/styles/variables";

/* -----------Layout container------------ */
.ant-layout {
  height: 100vh;
  overflow: hidden;
  /*background-color: $grey_bckg !important;*/
  background-color: $grey_bckg!important;
  /* -----------Sidebar Container------------ */
  .ant-layout-sider {
    /*background-color: $sidebar_primary_color;*/
    background-color: $grey_bckg!important;
    /* -----------collapsed sidebar popover------------ */
    .ant-menu.ant-menu-sub.ant-menu-vertical {
      /*background-color: $sidebar_primary_color;*/
      background-color: $grey_bckg!important;
    }
    /* -----------Items Menu container------------ */
    .ant-menu {
      /*background-color: $sidebar_primary_color !important;*/
      background-color: $grey_bckg!important;
      .sidebar_submenu_item {
        border-left: 5px solid transparent;
        padding-left: 43px !important;
      }
      .sidebar_menu_item,
      .ant-menu-submenu-title {
        border-left: 5px solid transparent;
        padding-left: 19px !important;
      }
      .ant-menu-submenu-selected {
        /*color: $active_item;*/
        color:$dark_blue;
      }
      .ant-menu-item-selected {
        background-color: rgba(0,0,0,.045) !important;
        border-left: 5px solid $active_item;
        border-radius:0 3px 3px 0;
      }
    }
  }
  /* -----------Navbar(header) and main content container------------ */
  .site-layout {
    .site-layout-background {
      /*background: $white;*/
      background:$grey_bckg;
    }
    .ant-layout-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px 0 0 !important;
      /*border-bottom: 1px solid $border_primary;
      box-shadow: 0 1px 2px rgba(0,0,0,.05);*/
      .ant-select-arrow{
        display: none;
      }
      .trigger {
        padding: 0 24px;
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
          color: $active_item;
        }
      }
      .utils {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 40px;
        i {
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
    .ant-layout-content {
      margin: 0 !important;
      background-color: $grey_bckg !important;
    }
    .ant-layout-footer {
      /*background: $white;
      border-top: 1px solid $border_primary;
      */
      text-align: center;
      padding: 10px 18px;
      font-weight: 300;
      color: $dark_blue;
    }
  }
}



