@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import "./assets/styles/variables";

body,.k-grid,.k-pager-wrap, .k-toolbar{font-family: Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important}
a {text-decoration: none!important;}
.page_container{box-shadow: 0 1px 2px rgba(0,0,0,.05)}
/* Kendo */
.k-grid,.ficheContent, ficheSuivi {border: 1px solid $border_primary;border-radius: 0.25rem;}
.k-grid,.k-grid-header{color:$dark_blue!important;}
.k-pager-wrap, .k-picker-md, .k-button-md, k-animation-container{font-size:$grid_font_size!important}
.k-grid-toolbar,.k-grid-pager {background-color: transparent!important}
.k-grid-sm, .k-button-sm{font-size:$grid_font_size_sm!important}


/* Ant */
.ant-menu-item{
  a{color:$dark_blue}
  &:hover{
    a, svg{
      color: #1890ff;
    }
  }
} 
.ant-select-clear{
 color: $is_inactif;
}
.ant-select-clear:hover{
  color: $is_inactif;
 }
.ant-menu-item-selected{
  a{
    color:$dark_blue;font-weight:600
  }
  .ant-menu-item-icon{color:$dark_blue}
} 
.ant-menu-submenu-selected{
  svg, .ant-menu-title-content{
    color: #1890ff;
  }
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border:none!important;
}

.ant-drawer-content{background-color:$grey_bckg!important;}

.ant-input-affix-wrapper-disabled,
.ant-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
.ant-select-selector {color: #7a7a7a !important;border-color: #d9d9d9 !important;}
//************INPUTS STYLE (Date Picker, input....)
.ant-picker-input input, .ant-form-item-control-input-content input{
font-size: 12px;
height: 24px;
}
.ant-form-item-control-input-content .numeric{
  text-align: right;
}

//************
.ant-btn{height: auto!important;}
.ant-input-search-button {height: 32px!important;}

.ant-tag{font-size:.7855em}
.ant-tag-green {color: #389e0d;background: #f6ffed;border-color: #a3e272;}

.ant-drawer-header{border-bottom-color: $border_primary!important;box-shadow: 0 1px 2px rgba(0,0,0,.05);background-color:#FFF}
.ant-drawer-body{background-color: $grey_bckg !important;}
.ant-drawer-footer{border-top-color: $border_primary!important;}

.ant-menu-horizontal{background-color: transparent!important;}

/* Suivi */
.suiviHeader a{color:$blue!important}
.ant-comment-content-detail p{font-size:.75rem}

/* Forms */
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background:$black!important;border-color:$black!important
}
.ant-radio-button-wrapper{
  border-color:$black!important;
}
.ant-form-item{
  margin-bottom: 0px !important;
}
.ant-radio-button-wrapper:hover{
  border-color:$black!important;color:$black!important;
}

/* Tabs */
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
  border-bottom:1px solid $border_primary!important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
  margin:0 -3px 0 0!important;
  border:1px solid $border_primary!important;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
  background-color:#f1f1f1
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
  border-bottom-color:#FFF!important;
  background-color: #FFF!important;
}
/* cheats */
.m-r-10{margin-right:10px}
.bg-transparent{background-color:transparent!important}
.bg-white{background-color:#FFF!important}
.noborder{border:none!important;border-radius:0px!important;}

//.ant-row{margin-right: -.625rem!important;margin-left: -.625rem!important;}
.ant-drawer-extra{flex: none!important;}
//[class^="ant-col"]{padding-right: 0.625rem!important;padding-left: 0.625rem!important;}