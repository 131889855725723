@import "../../../assets/styles/variables";
@import "../../../assets/styles//mixins/index.scss";

.immoConfig {
	@include generic_page_style;
	@include gridToolbarStyle;
	@include hiddenScrollbar;
	.ant-tabs-left {
		height: 67vh;
		overflow: hidden;
	}
	.toolbar_utils {
		.toolbar_icns2 {
			.k-button-outline-secondary {
				margin: 0px 10px;
			}
		}
	}
	.ant-tabs-nav-list {
		border-bottom: 1px solid $border_primary;
	}
	.k-grid-container {
		.customCell {
			width: 20px;
			height: 20px;
			border-radius: 20px;
			display: block;
			border: 1px solid white;
			bottom: 14px;
			right: 1px;
			margin: auto;
		}
	}
	.typical-sentence-cell {
		p {
			height: 21px;
			margin: 0px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
