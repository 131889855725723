@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins/index.scss";

.infoPratique_immoGestProp {
  .ant-row{
    .ant-form-item {
      padding-right: 20px;
      margin-bottom: 0px;
      .ant-form-item-label label{
          width: 90px !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-top: 5px;
          display: block;
          text-align: left;
          font-size: 12px;
      }
    }
}
}
