.antdselect {
  width: 100%;
  flex-flow: row nowrap;
  .selectComponent {
    .ant-select-selection-item {
      font-size: 12px;
      text-align: left;
    }
  }
}

.ant-select-item-option-content {
  font-size: 12px;
}
.ant-space-item{
  width: 100%;
  .ant-btn{
    width: 100%;
  }
}
.withAutres{
  .ant-select-item-option-content{
    color: #1890ff;
    font-weight: 600;
  }
}
.customDropDown{
  color: #40a9ff;
  &:hover{
    color: #0d6efd;
    font-weight: 600;
  }
}