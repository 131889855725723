@import '../../assets/styles/variables';
@import "../../assets/styles/mixins/index.scss";

.genericGridComponent{
    @include generic_grid;
    height: 100%;
    .ant-spin-nested-loading {
        height: 100%;
        .ant-spin-container {
            height: 100%;
        }
    }
    .rightAlign{
        padding-right: 10px;
        text-align: right;
    }
    .k-command-cell{
        text-align: right;
    }
    td input{
        border: none;
    }
}
