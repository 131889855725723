@import "../../assets/styles/variables";
@import "../../assets/styles/mixins/index.scss";

.news-drawer {
	@include generic_drawer;
	.submodule {
		.ant-tabs-nav {
			margin: 0px;
		}
	}
	.k-button-solid-primary {
		background-color: #f819ae;
		border-color: #f819ae;
	}
}
