@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins/index.scss";

.photosImmoGestProp {
  padding: 10px 10px;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  @include hiddenScrollbar;
  .header{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .maxPics{
      color: red;
      font-weight: 700;
    }
  }
  .consignes{
    height: fit-content;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-bottom: 10px;
  }
  .picsContainer{
    margin-top: 10px;
    padding: 20px;
    background-color: white;
    height: calc(100% - 170px);
    overflow-y: auto;
    .emtycontainer{
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-col-6{
        //padding: 0px 10px 10px 0px;
        height: 170px !important;
        div.polaroid {
            background-color: #fafafa;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            .img_container{
              position: relative;
                &:hover img{
                  opacity: 0.3;
                }
                &:hover .btns_tools{
                  opacity: 1;
                }
                img{
                  width: 100%;
                  height: 137px;
                  cursor: pointer;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                  opacity: 1;
                  display: block;
                  transition: .5s ease;
                  backface-visibility: hidden;

              }
              .btns_tools{
                transition: .5s ease;
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
                button{
                  margin-right: 10px;
                }
              }
            }
            .defaultcontainer{
                padding: 5px 15px 5px 15px;
                border-top: 2px solid #eeeeee;
                background-color: #eeeeee;
                p{
                    margin-bottom: 0;
                }
                span{
                    font-weight: 600;
                }
                .ant-checkbox{
                    padding-right: 10px;
                }
            }
          }
    }

  }

}

