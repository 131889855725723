@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins/index.scss";

.partners{
    @include generic_page_style;
    @include gridToolbarStyle;
    .toolbar_utils{
        .toolbar_icns:nth-child(2),.toolbar_icns:nth-child(3){
            .k-button {
                margin-left: 10px;
            }
        }
        .toolbar_icns{
            display: flex;
            flex-flow: row nowrap;
        }
        .k-button-solid-primary {
            background-color: #f819ae;
            border-color: #f819ae;
        }
    }
}