$grey_bckg:#eff1f5;
$white: white;
$black_title: #182c41;
$sidebar_primary_color: #252B36;
$green_online: #7ed321;
$active_item: #0F6EFF;
$dark_grey: #595959;

$bkg_sidebar_logo: rgba(0,0,0,0);

$light_blue: rgba(33, 150, 243, 0.07);
$dark_blue: #253858;
$grey: #a8b6be;
$blue-green: #00bdbb;
$blue_modal:#40a9ff;
$is_actif:#3ED552;
$is_inactif:#F72929;


$grid_font_size: .875rem;
$grid_font_size_sm: .825rem;
$blue: #2196f3;
$border_primary: #d4d4d4;
$black: #141220;