@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins/index.scss";

.caractImmoGestProp {
  padding: 10px 10px;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  @include hiddenScrollbar;
  .Topinfo{
    color: $grey;
    font-style: italic;
    font-weight: 400;
  }
  .ant-row{
    .inputNumberToChar{
      .ant-form-item-control-input-content{
        width: 70px;
      }
    }
    .ant-form-item {
      padding-right: 20px;
      margin-bottom: 0px;
      .ant-form-item-label label{
          width: 90px !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-top: 5px;
          display: block;
          text-align: left;
          font-size: 12px;
      }
      textarea{
          overflow: hidden;
      }
    }
    .lg-label .ant-form-item-label label{
      width: 180px !important;
    }
    .divided_col {
        display: flex;
        width: 100%;
        padding-left: 90px;
        justify-content: flex-start;

    }
    .radioo{
      .ant-form-item-label label{
        width: 120px !important;
      }
    }
}
.maxLabel{
  .ant-form-item .ant-form-item-label label{
    width: 125px !important;
  }
}
.inputNbrSlider{
  //overflow-x: scroll;
  width: 100%;
  display: flex;
  //flex-flow: row nowrap;
  .ant-form-item {
    //flex-flow: row nowrap;
    .ant-input-number {
      width: 100%;
    }
    .ant-form-item-label label{
      width: 110px !important;
    }
  }
}
.energyClasses {
  .ant-form-item {
    .ant-form-item-label label{
      width: 14rem !important;
    }
  }
}
#ges-info-icon{
  color: #000;
}
#GES-info:hover #ges-info-icon{
  color: #555;
}
.limite{
  border-bottom: 1px solid $blue-green;
  border-right: 1px solid $blue-green;
  border-left: 1px solid $blue-green;
  padding: 10px 0px 10px 10px;
  .ant-input-number-input-wrap > .ant-input-number-input  {
    text-align: end !important;
  }
  .ant-input-number-group-addon {
    min-width: 3rem;
    max-width: 3rem;
  }
  .ant-input-number-group-wrapper {
    width: 75%;
  }
}
.configImmo{
  .configTitle{
    font-weight: bold;
    width: 100%;
  }
  .configBody{
    display: flex;
    flex-flow: row wrap;
    padding: 5px 15px;
    label, .ant-form-item-control{
      margin-bottom: 0px !important;
    }
    .ant-checkbox + span {
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.ant-tabs-nav{
  margin: 0px;
}
.ant-tabs-content-holder{
  .limite{
    padding: 20px 5px 10px 5px;
    border-bottom: 1px solid $border_primary;
    border-right: 1px solid $border_primary;
    border-left: 1px solid $border_primary;
  }
}
.ant-divider-horizontal.ant-divider-with-text-left {
  margin: 0;
  &::before {
    height: 20px;
    border-top: 1px solid $blue-green !important;
    border-left: 1px solid $blue-green;
  }
  &::after {
    height: 20px;
    border-top: 1px solid $blue-green!important;
    border-right: 1px solid $blue-green;
  }
  .ant-divider-inner-text {
    font-size: 14px;
    font-weight: 700;
  }
}
.submodule{
  .submodule_Title{
    padding: 10px 0px;
    font-size: 17px;
    font-weight: 600;
    color: $blue-green;
  }
  label{
    width: 125px;
  }
}
}
@media only screen and (max-width: 575px) {
  .divided_col, .pushed_col{
    padding-left: 0px !important;
  }
}
