.notesinternes{
    flex: 1;
    .ant-row{
        width: 100%;
        .ant-form-item{
            width: 100%;
        }
        .ant-form-item-row {
            height: 100%;
            .ant-form-item-control-input {
                height: 100%;
                .ant-form-item-control-input-content {
                    height: 100%;
                    .ant-input-affix-wrapper{
                        height: 100%;
                        textarea{
                            overflow: auto;
                        }
                    }
                }
            }
        }
    }
    .limite {
        height: 100%;
    }
}